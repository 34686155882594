<template>
  <div class="container-fluid p-2 pt-3" style="scrollbar-width: none; -ms-overflow-style: none; background: radial-gradient(circle, #7b0e7f 0%, #431044 90%); height: 100vh; overflow-y: scroll; padding-bottom: 15rem;;
      background-attachment: fixed;">
        <div style="height: 200vh;">
          <!-- <div class="my-3 d-flex flex-wrap px-0 mx-0">
            <button class="detail-button w-auto text-nowrap mx-1 my-1">ALL</button>
            <button class="detail-button w-auto text-nowrap my-1">NEW MEMBER</button>
            <button class="detail-button w-auto text-nowrap mx-1 my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap mx-1 my-1">LIVE CASINO</button>
            <button class="detail-button w-auto text-nowrap my-1">LIVE CASINO</button>
          </div> -->
          <div class="col-12 px-1 mb-5 pb-5">
            <h2 class="text-white text-center mt-5">Coming Soon ...</h2>
            <!-- <div class="mt-2 mb-3" style="box-shadow: none; background: #242424; border-radius: 2rem;" v-for="(promotion, proIndex) in promotions" :key="proIndex">
              <a href="#">
                <img :src="promotion.image" style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem; width: 100%;" alt="">
              </a>
                <div class="px-3 py-2 d-flex justify-content-between">
                  <h3 class="text-white my-auto">
                      <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.webp" type="image/webp">
                      <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f4b8/512.gif" alt="💸" width="25" height="25">
                    </picture>
                    {{ promotion.title }}
                  </h3>
                  <button class="detail-button text-white col-3 my-2" @click=" promotionIndex(proIndex)">{{$t('detail')}}</button>
                </div>

                <van-overlay :show="selectedPromotion === proIndex" @click="selectedPromotion = null">
                    <div class="wrapper col-12 d-flex h-100vh">
                      <div class="col-12 mx-auto my-auto py-4 px-4" style="background: #202124 !important;;">
                        <h2 class="pb-3" style="color: #fff"> {{ promotion.title }}</h2>
                        <span style="color: #fff" v-html="promotion.description"></span>
                      </div>
                    </div>
                </van-overlay>
            </div> -->
          </div>
        </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      // websiteInfo: [],
      isLoading: false,
      selectedPromotion: null,
      promotions: [
        {
          title: 'မန်ဘာသစ်ပိုမိုရိုးရှင်း 50%',
          description: `✅ အနည်းဆုံး 3,000 ကစသွင်းပါကဒီပိုမိုရိုးရှင်းရနိုင်ပါတယ်ရှင့်။ <br><br> ✅ အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 25,000 ဖြစ်ပါသည်။ <br><br> ✅ နိုင်ငွေ 5ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br> ✅ နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။`,
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/1.png',
        },
        {
          title: 'နေ့စဥ်ဘောနပ် 30% တစ်နေ့ ( 1ကြိမ် )',
          image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/pg-boss/cards/2.png',
          description: `✅ အနည်းဆုံး 3,000 ကစသွင်းပါကဒီပိုမိုရိုးရှင်းရနိုင်ပါတယ်ရှင့်။ <br><br> ✅ အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 50,000 ဖြစ်ပါသည်။ <br><br> ✅ နိုင်ငွေ 3ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br> ✅ နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။`,
        },
        // {
        //   title: 'သွင်းတိုင်းရ 10%',
        //   image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/space97/space-home-2%20(1).png',
        //   description: `✅ အနည်းဆုံး 3,000 ကစသွင်းပါကဒီပိုမိုရိုးရှင်းရနိုင်ပါတယ်ရှင့် (တစ်နေ့2ကြိမ်)။ <br><br> ✅ အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 30,000 ဖြစ်ပါသည်။ <br><br> ✅ နိုင်ငွေ 1ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br> ✅ နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။`,
        // },
        // {
        //   title: 'စနေ တနင်္ဂနွေ ပိုမိုရိုးရှင်း 50% ( တစ်ကြိမ် )',
        //   image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/space97/space-home-2%20(1).png',
        //   description: `✅ အနည်းဆုံး 5,000 ကစသွင်းပါကဒီ ပိုမိုရိုးရှင်းရနိုင်ပါတယ်ရှင့်။ <br><br> ✅ အမြင့်ဆုံးရယူနိုင်သည့် Bonus မှာ ငွေကျပ် 25,000 ဖြစ်ပါသည်။ <br><br> ✅ နိုင်ငွေ 5ဆ ပြည့်မှသာ ငွေထုတ်ပေးပါမည်။ <br><br> ✅ နိုင်ငွေ ပြည့်ပြီးပါက ကန့်သတ်ချက်မရှိ ငွေပြန်လည်ထုတ်ယူနိုင်ပါသည်။`,
        // },
        // {
        //   title: 'Birthday Bonus 20,000 MMK',
        //   image: 'https://space-tech.sgp1.cdn.digitaloceanspaces.com/space97/space-home-2%20(1).png',
        //   description: `► ဤပရိုမိုးရှင်းကို မန်ဘာဝင်တိုင်း တစ်နှစ် တစ်ကြိမ်ရယူနိုင်ပါသည်။ <br><br> ► ၎င်းပရိုမိုးရှင်းအား ရယူရန် Customer Service သို့ဆက်သွယ်ရပါမည်။ <br><br> ► မန်ဘာဝင်တိုင်းသည် တစ်ပတ်လျှင် 20,000 Ks အထက် အပတ်စဉ် 4 ပတ်ဆက်တိုက်ရှိရမည်။ <br><br> ► သင်၏ ID ကတ်ကို စစ်ဆေးရန် customer service ထံပုံရိုက်ပြရမည်။ <br><br> ► ဘောနပ်စ် 20,000Ks ( 09:00 - 21:00 ) တွင်ထုတ်ပေးမည်။ <br><br> ► TurnOver 1 ဆ ပြုလုပ်ရမည်။ ► ဤပရိုမိုးရှင်းသည် ဂိမ်းများအားလုံးကိုကစားနိုင်ပါသည်။ <br><br> ⚠️ မှတ်ချက် ⚠️ <br><br> • ဤပရိုမိုးရှင်းကို Salmon မှကြိုတင်အသိပေးခြင်းမရှိဘဲအချိန်မရွေးပြောင်းလဲနိုင်ပါသည်။ <br><br> • ဤပရိုမိုးရှင်းအား တစ်စုံတစ်ရာ အငြင်းပွားဖွယ်ရှိပါက Salmon ၏ဆုံးဖြတ်ချက်သာ အတည်ဖြစ်သည်။`,
        // },
      ]
    };
  },
  methods: {
    promotionIndex(index) {
      this.selectedPromotion = index;
    },
    // async fetchWebsiteData() {
    //   try {
    //     const res = await axios.get('/website-infos');
    //     this.websiteInfo = res.data.data[0];
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
  async mounted() {
    // this.$store.commit('setLoading', true);
    // await this.fetchWebsiteData();
    // this.$store.commit('setLoading', false);
  },
};
</script>
<style scoped>
.detail-button {
  appearance: none;
  /* background-color: transparent; */
  background: radial-gradient(circle at 10% 20%, rgb(241, 121, 226) 0%, #DD4DD1 86.8%) !important;
  border: none;
  text-transform: uppercase;
  border-radius: 14px;
  box-sizing: border-box;
  color: black !important;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 0.9rem;
  font-weight: 450;
  line-height: normal;
  margin: 0;
  min-height: 15px;
  min-width: 0;
  outline: none;
  padding: 1px 5px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.detail-button:disabled {
  pointer-events: none;
}

.detail-button:hover {
  color: black;
  background: radial-gradient(circle at 10% 20%, rgb(241, 121, 226) 0%, #DD4DD1 86.8%) !important;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px; */
  transform: translateY(-2px);
  /* border: black !important; */
}

.detail-button:active {
  box-shadow: none;
  transform: translateY(0);
  /* border: black !important; */
  background: #2B2C2E;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #B50200 0%,
    #f3e706 29%,
    #B50200 67%,
    #f3e706 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      /* font-size: 190px; */
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>