<template>
    <div class="container-fluid d-flex flex-wrap main-wrapper" style="background: radial-gradient(circle, #7b0e7f 0%, #431044 90%); height: 100vh; overflow-y: scroll; background-size: cover; padding-bottom: 15rem !important; background-attachment: fixed;">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-12 p-0 mb-5">
            <div class="bg-transparent">
                <div class="pl-2 pt-3 bg-transparent">
                    <div class="align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0" style="color: #fff !important;">
                                {{$t('withdraw.withdraw_form')}}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="error text-center text-success py-2" v-if="error">
                    <span class="mx-auto">
                      {{error}}
                    </span>
                    
                  </div>
                <div class="card-body bg-transparent">
                    <form class="mt-3">
                      <nav class="side-nav my-2" style="border-radius: 10px; background: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%);">
                        <div class="text-center py-2 pt-3">
                          <img @click="$router.push('/banking')" style="position: absolute; height: 2rem; right: 2.5rem;" src="https://imagedelivery.net/HPdN1Z_Ube9zTuVFQK-tGg/dc500777-e8e3-4267-ace1-cdcf5a215600/public" alt="">
                          <h3 class="text-dark">{{ bankInfo.type }}</h3>
                          <h3 class="text-dark">{{ bankInfo.name }}</h3>
                          <h3 class="text-dark">{{ blurLastFiveCharacters(bankInfo.account_number) }}</h3>
                        </div>
                      </nav>

                        <!-- <div class="col-lg-12 mt-4 px-0 mx-0">
                              <label class="form-control-label" style="color: #ccc !important; font-size: 1rem; font-weight: 400 !important;">{{$t('withdraw.current_turnover')}}  -  </label>
                              <span class="text-green text-md" style="font-size: 1.1rem; font-weight: 400 !important;"> {{ Number($store.state.authUser.total_bet_amount).toLocaleString() }}</span> <br>

                              <label class="form-control-label" style="color: #ccc !important; font-size: 1rem; font-weight: 400 !important;">{{$t('withdraw.available_withdrawal_amount')}}  -  </label>
                              <span style="font-size: 1.1rem; font-weight: 400 !important;" class="text-green text-md" v-if="($store.state.authUser.total_bet_amount - $store.state.authUser.all_withdraw) > 0"> {{ Number($store.state.authUser.total_bet_amount - $store.state.authUser.all_withdraw).toLocaleString() }}</span>
                              <span style="font-size: 1.1rem; font-weight: 400 !important;" class="text-green text-md" v-else> 0</span>
                              
                              <div class="col-12 px-0 mx-0">
                                  <span class="text-muted" style="font-size: 0.8rem; font-weight: 400 !important;">
                                    စုစုပေါင်း Turn Over ( ကစားအား ) သည် ထုတ်လိုသောငွေ၏ အနည်းဆုံး တစ်ဆ အထက်ရှိရမည်။ ဥပမာ - ငွေ တစ်သိန်း ထုတ်လိုပါက Turn Over ( ကစားအား ) တစ်သိန်းအထက်ရှိရမည် ဖြစ်သည်။
                                  </span>
                              </div>
                        </div> -->
                          
                        <div class="pt-5">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="form-control-label" style="color: #fff !important; font-size: 1rem; font-weight: 400 !important;">{{$t('withdraw.amount')}}&nbsp;&nbsp;</label>
                                        <span style="color: #ccc" class="text-xs">{{$t('withdraw.minimum_deposit')}}</span>
                                        <input
                                            type="number"
                                            id="input-email"
                                            class="form-control custom-input"
                                            v-model="withdrawData.amount"
                                        />
                                        <span class="text-success">{{
                                            errors.amount
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="form-group">
                                <label class="form-control-label" style="color: #fff !important; font-size: 1rem; font-weight: 400 !important;">{{$t('withdraw.remark')}}</label>
                                <textarea
                                    rows="4"
                                    class="form-control custom-input"
                                    style="border-radius: 15px;"
                                    v-model="withdrawData.remark"
                                ></textarea>
                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn btn-primary custom-btn"
                                    @click="isConfirm ? '' : sendWithdrawData()"
                                >
                                    <div v-if="!isConfirm">
                                        {{$t('withdraw.submit')}}
                                    </div>
                                    <Loading
                                        color="#000000"
                                        v-if="isConfirm"
                                        size="24"
                                        class="px-4 py-0"
                                    />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
import { mapActions } from 'vuex';
import AccountSelect from './components/AccountSelect.vue';

export default {
  components: { AccountSelect, Loading },
  data() {
    return {
      imageUploader: [],
      selected_account: {
        account_id: null,
      },
      bankInfo: {},
      accounts: [
        {
          name: 'KBZ pay',
          id: 'KBZ pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/kpay.png',
        },
        {
          name: 'KBZ pay',
          id: 'KBZ pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/kpay.png',
        },
        {
          name: 'KBZ Mobile Banking',
          id: 'KBZ Mobile Banking',
          link: 'https://cdn.gold549.com/build/assets/img/payments/kbz.png',
        },
        {
          name: 'Wave pay',
          id: 'Wave pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/wavepay.png',
        },
        {
          name: 'CB Pay',
          id: 'CB Pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/cb_pay.png',
        },
        {
          name: 'CB Banking',
          id: 'CB Banking',
          link: 'https://cdn.gold549.com/build/assets/img/payments/cbbank.png',
        },
        {
          name: 'AYA Pay',
          id: 'AYA Pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/ayapay.png',
        },
        {
          name: 'AYA Banking',
          id: 'AYA Banking',
          link: 'https://cdn.gold549.com/build/assets/img/payments/aya.png',
        },

      ],
      account: [],
      imageUploader: [],
      withdrawData: {},
      errors: {},
      isConfirm: false,
    };
  },
  methods: {
    blurLastFiveCharacters(inputString = "0000000000") {
    // Get the length of the input string
    const length = inputString.length;

    // Check if the input string has at least five characters
    if (length >= 7) {
      // Extract the first part of the string
      const firstPart = inputString.substring(length - 5, length);

      // Create a string with '*' characters for the last five numbers
      const blurredCharacters = '*'.repeat(length-5);

      // Concatenate the first part and the blurred numbers
      const blurredString = blurredCharacters + firstPart;

      return blurredString;
    } else {
      // Return the input string unchanged if it has less than five characters
      return inputString;
    }
  },
    ...mapActions(['fetchUser']),
    async sendWithdrawData() {
      try {
        this.isConfirm = true;
        const authUser = await this.fetchUser();
        this.errors = {};
        // if ((this.$store.state.authUser.total_bet_amount - this.$store.state.authUser.all_withdraw) < this.withdrawData.amount) {
        //   this.errors.amount = this.$store.state.language === 'en'
        //     ? 'Withdrawal amount should be greater than Turn Over Amount'
        //     : 'ထုတ်ယူသည့်ပမာဏသည် Turn Over Amount ထက် ပိုများနေရပါမည်။';
        //     this.isConfirm = false;
        //   return false;
        // }
        if (!this.withdrawData.amount) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter amount'
            : 'ငွေပမာဏထည့်ပေးပါ။';
  this.isConfirm = false;          
            return false;
        }
        if (this.withdrawData.amount < 10000) {
          this.errors.amount = this.$store.state.language === 'en'
            ? 'Please enter at least 10000 MMK'
            : 'ငွေပမာဏအနည်းဆုံး ၁၀၀၀၀ ထည့်ပေးပါ။';
  this.isConfirm = false;          
            return false;
        }
        if (this.withdrawData.amount > parseInt(authUser.amount)) {
          const balanceError = this.$store.state.language === 'en'
            ? "You Don't Have Sufficient Balance"
            : 'အကောင့်ထဲတွင် ပိုက်ဆံအလုံအလောက် မရှိပါ။';
          Dialog.alert({
            message: balanceError,
            confirmButtonText:
                            this.$store.state.language === 'en'
                              ? 'Go Back'
                              : 'နောက်သို့ပြန်သွားမည်။',
          }).then(() => {
            // on close
          });
          this.isConfirm = false;
          return false;
        }
        this.$store.commit(
          'setAmount',
          (this.$store.state.amount -= this.withdrawData.amount),
        );
        
        if (this.imageUploader[0]) {
          this.withdrawData.transaction_image = this.imageUploader[0].content;
        }
        // this.withdrawData.type = this.selected_account.account_id;
        this.withdrawData.date = this.currentTime;
        this.withdrawData.account_number = this.bankInfo.account_number;
        this.withdrawData.account_name = this.bankInfo.name;
        this.withdrawData.type = this.bankInfo.type;
        this.withdrawData.lang = localStorage.getItem("language");

        const res = await axios.post('/withdraws', this.withdrawData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        const resUser = await axios.post(
          `/admin/user/${this.$store.state.authUser.id}`,
          {
            amount: this.withdrawData.amount,
            type: 'withdraw',
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          }
        );
        this.imageUploader = [];
        this.withdrawData = {};
        this.selected_account.account_id = null;
        await this.fetchUser();
        Toast.success('Success');
        this.isConfirm = false;
      } catch (error) {
        Toast.fail('Fail');
        this.error = error.response.data
        this.isConfirm = false;
      }
      return true;
    },
    createDateWithTimeZone() {
      const moment = require('moment-timezone');
      const dateInTimeZone = moment.tz(new Date(), 'Asia/Rangoon');
      // Extract individual components from moment object
      const year = dateInTimeZone.year();
      const month = dateInTimeZone.month();
      const day = dateInTimeZone.date();
      const hour = dateInTimeZone.hour();
      const minute = dateInTimeZone.minute();
      const second = dateInTimeZone.second();
      const millisecond = dateInTimeZone.millisecond();

      // Create a new Date object with the extracted components
      const newDateObject = new Date(year, month, day, hour, minute, second, millisecond);
      return newDateObject;
    },
  },
  //   watch: {
  //     'selected_account.account_id': function () {
  //       this.account = this.accounts.filter(
  //         (account) => account.id === this.selected_account.account_id,
  //       );
  //     },
  //   },
  computed: {
    currentTime() {
      return moment(this.createDateWithTimeZone()).format('lll');
    },
  },
  async mounted() {
    // this.$store.commit('setLoading', true);
    const authUser = await this.fetchUser();
    // this.$store.commit('setLoading', false);
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    // if(authUser && !authUser.is_verified) {
    //   this.$router.push('/auth/verify');
    // }
    if(authUser && (!authUser.bank.account_number || !authUser.bank.name)){
      this.$router.push('/banking')
    }
    this.bankInfo = authUser.bank;
  },
};
</script>
<style scoped>
.error {
  margin-bottom: 13px;
  /* height: 40px; */
  display: flex;
  align-items: center;
  color: white;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 8px;
  padding: 0px 15px;
  background: transparent;
  /* font-weight: bold; */
}

.custom-btn {
  background: radial-gradient(circle at 10% 20%, rgb(241, 121, 226) 0%, #DD4DD1 86.8%) !important;
  color: white;
  width: 100% !important;
  border-radius: 25px;
  height: 40px !important;
}
.custom-input, .custom-input:active, .custom-input:focus {
    background: transparent !important;
    color: white !important;
    border-radius: 30px;
    border: 1px solid #a4a4a4 !important;
    text-align: center;
    /* height: 50px; */
    font-size: 1.3rem;
    font-weight: 300;
    box-shadow: none !important;
}
.image-container {
    width: 200px;
    height: 200px;
}
.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.number-container {
    border-radius: 5px;
    background: #e9ecef;
    justify-content: center;
}
.custom-file {
    height: auto;
}
@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
}
</style>
