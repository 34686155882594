<template>
    <div class="container-fluid d-flex flex-wrap banking main-wrapper" style="background: radial-gradient(circle, #7b0e7f 0%, #431044 90%); height: 100vh; overflow-y: scroll; background-size: cover; padding-bottom: 15rem !important; background-attachment: fixed;">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-12 p-0 mb-5" style="height: 110vh;">
            <div class="bg-transparent">
                <div class="pl-2 pt-3 bg-transparent">
                    <div class="align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0" style="color: #fff !important;">
                                {{$t('banking.bank_info')}}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="error text-center py-2" v-if="error">
                    <span class="mx-auto">
                      {{error}}
                    </span>
                    
                  </div>

                <div class="card-body bg-transparent">
                    <form>
                        <div class="mt-3">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            style="color: #fff !important; font-size: 1rem; font-weight: 400 !important;"
                                            >{{$t('banking.bank_username')}}</label
                                        >
                                        <input
                                            type="text"
                                            class="form-control custom-input"
                                            v-model="
                                                account.name
                                            "
                                        />
                                        <span class="text-danger">{{
                                            errors.name
                                        }}</span>
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label
                                            class="form-control-label"
                                            for="input-email"
                                            style="color: #fff !important; font-size: 1rem; font-weight: 400 !important;"
                                            >{{$t('banking.bank_number')}}</label
                                        >
                                        <input
                                            type="number"
                                            class="form-control custom-input"
                                            v-model="
                                                account.account_number
                                            "
                                        />
                                        <span class="text-danger">{{
                                            errors.number
                                        }}</span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label
                                                    class="form-control-label"
                                                    for="input-username"
                                                    style="color: #fff !important; font-size: 1rem; font-weight: 400 !important;"
                                                    >{{$t('banking.bank_type')}}</label
                                                >
                                                <AccountSelect
                                                    :data="account"
                                                    field="type"
                                                    :accounts="accounts"
                                                />
                                                <span class="text-danger">{{
                                                    errors.account
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12 mt-2">
                                    <button
                                        type="button"
                                        class="btn btn-primary custom-btn"
                                        @click=" updateBankInfo()"
                                    >
                                        <div v-if="!isConfirm">
                                            {{$t('banking.submit')}}
                                        </div>
                                        <Loading
                                            color="#ffffff"
                                            v-if="isConfirm"
                                            size="24"
                                            class="px-4 py-0"
                                        />
                                    </button>
                                </div>
                                <div class="col-12">
                                  <h3 style="color: #ccc;font-size: 1rem; font-weight: 400 !important;">** {{$t('banking.note')}} **</h3>
                                  <span style="color: #ccc; font-size: 0.9rem;">
                                    {{ $t("banking.note_content") }}
                                  </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast, Dialog, Loading } from 'vant';
import { mapActions } from 'vuex';
import AccountSelect from './components/AccountSelect.vue';

export default {
  components: { AccountSelect, Loading },
  data() {
    return {
      imageUploader: [],
      selected_account: {
        account_id: null,
      },
      error: null,
      accounts: [
        {
          name: 'KBZ pay',
          id: 'KBZ pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/kpay.png',
        },
        {
          name: 'KBZ Mobile Banking',
          id: 'KBZ Mobile Banking',
          link: 'https://cdn.gold549.com/build/assets/img/payments/kbz.png',
        },
        {
          name: 'Wave pay',
          id: 'Wave pay',
          link: 'https://cdn.gold549.com/build/assets/img/payments/wavepay.png',
        },
      ],
      account: [],
      imageUploader: [],
      withdrawData: {},
      errors: {},
      isConfirm: false,
    };
  },
  methods: {
    async fetchBankInfo() {
      try {
        const res = await axios.get('/user_bank', 
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
          }
        );
        this.account = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    ...mapActions(['fetchUser']),
    async updateBankInfo() {
      try {
        this.error = null;
        if (!this.account.type) {
          this.error = this.$store.state.language === 'en'
            ? 'Please pick account type'
            : 'အကောင့်အမျိုးအစား ရွေးချယ်ပေးပါ။';
          return false;
        }
        if (!this.account.account_number) {
          this.error = this.$store.state.language === 'en'
            ? 'Please enter account number'
            : 'အကောင့်နံပါတ် ထည့်သွင်းပေးပါ။';
          return false;
        }
        if (!this.account.name) {
          this.error = this.$store.state.language === 'en'
            ? 'Please enter bank username'
            : 'Please enter bank username';
          return false;
        }

        this.isConfirm = true;

        await axios.put('/user_bank', this.account, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        this.error = null;
        Toast.success('Success');
        this.isConfirm = false;
      } catch (error) {
        this.error = error.response.data
        this.isConfirm = false;
      }
      return true;
    },
  },
  async mounted() {
    // this.$store.commit('setLoading', true);
    await this.fetchBankInfo()
    const authUser = await this.fetchUser();
    // this.$store.commit('setLoading', false);
    if (!authUser) {
      this.$router.push('/auth/login');
    }
    // if(authUser && !authUser.is_verified) {
    //   this.$router.push('/auth/verify');
    // }
  },
};
</script>
<style scoped>
.error {
  margin-bottom: 0;
  /* height: 40px; */
  display: flex;
  align-items: center;
  color: black;
  border: 1px solid rgb(255, 0, 0);
  border-radius: 8px;
  padding: 0px 15px;
  margin-top: 2rem;
  background: transparent;
  /* font-weight: bold; */
}

.custom-btn {
  /* background: #FFFCFC !important; */
  background: radial-gradient(circle at 10% 20%, rgb(241, 121, 226) 0%, #DD4DD1 86.8%) !important;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  width: 100% !important;
  border-radius: 25px;
  height: 40px !important;
}
.custom-input, .custom-input:active, .custom-input:focus {
    background: transparent !important;
    color: white !important;
    border-radius: 30px;
    /* border: 1px solid #FFFCFC !important; */
    border: 1px solid #a4a4a4 !important;
    text-align: center;
    /* height: 50px; */
    font-size: 1.3rem;
    font-weight: 300;
    box-shadow: none;
}
.image-container {
    width: 200px;
    height: 200px;
}
.image-container img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.number-container {
    border-radius: 5px;
    background: #e9ecef;
    justify-content: center;
}
.custom-file {
    height: auto;
}
/* @media (max-width: 768px) { */
    .main-wrapper {
        padding: 7px !important;
    }
/* } */
</style>
